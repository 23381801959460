import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
    CapitalizePipe, ConfigurationService,
    Consumer, CookiesService,
    DateService,
    FormValidators,
    GiftUser,
    MobileService,
    MsConsumersService,
    Offer, Operation,
    UserService
} from '@isifid/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConsumerService} from '../../../../shared/services/consumer.service';
import {GiftService} from '../../../../shared/services/gift.service';
import {GiftUserService} from '../../../../shared/services/gift-user.service';
import {DialogUpdateBranchCodeComponent} from '../../../account/dialog-update-branch-code/dialog-update-branch-code.component';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {CustomFieldService} from '../../../../shared/services/custom-field.service';

@Component({
    selector: 'app-reward-form',
    templateUrl: './reward-form.component.html'
})
export class RewardFormComponent implements OnInit, OnChanges {
    @Input() offer: Offer;
    @Input() operation: Operation;
    @Input() resetForm = false;
    @Output() consumerEvent = new EventEmitter<{ consumer: Consumer, rewardProperties: any[] }>();

    branchList = [];
    customReward: boolean;
    customRewardFields = [];
    consumersSearch: Array<Consumer> = [];
    form: FormGroup;
    loading: boolean;
    showExtendedForm = false;

    private rewardProperties = [];
    private branchCode: string = '';
    private giftUser: GiftUser;

    constructor(
        private readonly dialog: MatDialog,
        private readonly capitalizePipe: CapitalizePipe,
        private readonly consumerService: ConsumerService,
        readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly mobileService: MobileService,
        private readonly msConsumersService: MsConsumersService,
        private readonly formValidators: FormValidators,
        private readonly userService: UserService,
        private readonly dateService: DateService,
        private readonly cookiesService: CookiesService,
        private readonly customFieldService: CustomFieldService,
        private readonly configurationService: ConfigurationService
    ) {
    }

    ngOnInit() {
        this.initForm();
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => {
            this.giftUser = s;
            this.branchList = s.branchList.filter(branchCode => branchCode);
            if (this.branchList.length) {
                this.branchCode = this.giftService.getFirstBranchParsedAsString(this.branchList);
                this.form.controls.branchCode.setValue(this.branchList[0]);
            } else if (this.userService.hasRole('GIFT_HQ')) this.branchCode = 'siege';
        });
    }

    // ngOnChanges to detect change in consumer input
    ngOnChanges(e) {
        if (e.offer) this.initForm();
        if (e.resetForm) this.initForm(true);
    }

    initForm(reset?: boolean) {
        this.loading = false;
        let rawValue = null;
        if (this.form?.getRawValue().mobile && !reset) rawValue = this.form.getRawValue();
        this.form = new FormGroup({
            mobile: new FormControl('', [Validators.required, this.formValidators.isInternationalPhone]),
            email: new FormControl('', [Validators.required, Validators.email, this.formValidators.isEmail]),
            branchCode: new FormControl('', []),
            id: new FormControl('', [])
        });
        if (this.giftService.getConfigurationValue('mandatoryExternalId') === true) {
            this.form.addControl('externalId', new FormControl('', [
                Validators.required,
                Validators.minLength(this.giftService.giftNetworkVariables?.idLength || 5),
                Validators.maxLength(this.giftService.giftNetworkVariables?.idLength || 30)
            ]));
        }
        if (this.giftService.getConfigurationValue('simpleReward') === false) {
            this.form.addControl('firstName', new FormControl('', [Validators.required]));
            this.form.addControl('lastName', new FormControl('', [Validators.required]));
            this.form.addControl('birthDate', new FormControl(''));

            if (this.configurationService.getValueByKey(this.operation.configuration, 'mandatoryGender') === true)
                this.form.addControl('gender', new FormControl('', [Validators.required]));
            else this.form.addControl('gender', new FormControl(''));
        }
        this.initCustomFields();
        if (rawValue) this.updateForm(rawValue);
    }

    updateForm(consumer?: Consumer) {
        if (consumer?.mobile) this.form.controls.mobile.setValue(consumer?.mobile || '');
        this.form.controls.email.setValue(consumer?.email || '');
        this.form.controls.branchCode.setValue(this.branchList[0]);
        this.form.controls.id.setValue(consumer?.id || '');
        if (this.giftService.getConfigurationValue('mandatoryExternalId') === true) {
            let externalId = consumer?.externalId || '';
            // Don't set externalId if it contains a :
            if (externalId.indexOf(':') !== -1) externalId = '';
            this.form.controls.externalId.setValue(externalId);
            // Disable externalId if already set
            if (externalId) this.form.controls.externalId.disable();
        }
        // Set form fields if not simpleReward
        this.setFormNoSimpleReward(consumer);
        // Disable form fields except mobile if consumer is found
        if (consumer?.id) this.disableFormFields();
    }

    private setFormNoSimpleReward(consumer: Consumer) {
        if (this.giftService.getConfigurationValue('simpleReward') === false) {
            this.form.controls.firstName.setValue(consumer?.firstName || '');
            this.form.controls.lastName.setValue(consumer?.lastName || '');
            this.form.controls.birthDate.setValue(consumer?.birthDate || '');
            this.form.controls.gender.setValue(consumer?.gender || '');
        }
    }
    private disableFormFields() {
        if (this.giftService.getConfigurationValue('simpleReward') === false) {
            if (this.form.controls.firstName.value) this.form.controls.firstName.disable();
            if (this.form.controls.lastName.value) this.form.controls.lastName.disable();
            if (this.form.controls.birthDate.value) this.form.controls.birthDate.disable();
            if (this.form.controls.gender.value) this.form.controls.gender.disable();
            this.showExtendedForm = true;
        }
    }

    initCustomFields() {
        this.customRewardFields = [];
        this.customReward = false;
        if (!this.offer) return;
        // Get custom fields from the operation and offer
        this.customRewardFields = [
            ...this.customFieldService.getCustomFieldsFromConfiguration(this.operation.configuration),
            ...this.customFieldService.getCustomFieldsFromConfiguration(this.offer.configuration)
        ];

        // If custom fields for offer set customReward to true
        this.customReward = this.customRewardFields?.length > 0;
        this.customRewardFields.forEach(field => {
            this.form.addControl(field.name.toString(), new FormControl(field.defaultValue || '', field.required ? [Validators.required] : []));
        });
    }

    createConsumer() {
        this.loading = true;
        let value: any;
        // Add reward properties
        if (this.customReward) {
            this.customRewardFields.forEach(field => {
                if (field.saveInDatabase) {
                    value = this.form.get([field.name]).value;
                    if (field.name === 'birthDate' && value) value = this.convertDateTime(this.form.get([field.name]).value);
                    this.rewardProperties.push(value);
                }
            });
        }

        const consumerData = new Consumer();
        Object.assign(consumerData, this.form.getRawValue());
        consumerData.clientId = this.giftService.settings.clientId;
        if (consumerData.mobile) consumerData.mobile = this.mobileService.formatMobile(consumerData.mobile);
        if (consumerData.birthDate) consumerData.birthDate = this.convertDateTime(consumerData.birthDate);

        const observer = consumerData.id ?
            this.msConsumersService.updateConsumer(consumerData.id, consumerData) :
            this.msConsumersService.createConsumer(consumerData, false);

        observer.subscribe({
            next: (consumer) => {
                if (!consumer.branchCode || !consumer.giftUserId) this.addProperties(consumer.id);

                this.sendConsumer(this.rewardProperties, consumer);
            }, error: () => console.error('error while creating consumer')
        });
    }

    getConsumer() {
        this.loading = true;
        this.form.enable();
        this.consumersSearch = [];
        this.consumerEvent.emit({consumer: null, rewardProperties: []});
        if (this.giftService.getConfigurationValue('simpleReward') === false) this.showExtendedForm = false;
        this.consumerService.getConsumerByMobile(this.mobileService.formatMobile(this.form.get('mobile').value)).subscribe({
            next: (consumers: Array<Consumer>) => {
                // Set consumer to null to reset form if no consumer found
                let consumer = null;
                // If only one consumer found set consumer to this consumer
                if (consumers && consumers.length === 1) consumer = consumers[0];
                // If more than one consumer found display consumers to select
                else if (consumers && consumers.length > 1) this.consumersSearch = consumers;
                this.updateForm(consumer);
            }, error: () => console.error('error while getting consumer'),
            complete: () => this.loading = false
        });
    }

    sendConsumer(rewardProperties = [], consumer?: Consumer) {
        this.consumerEvent.emit({consumer: consumer, rewardProperties: rewardProperties});
    }

    private addProperties(consumerId) {
        let branchCode = this.branchCode;
        if (!branchCode) branchCode = this.form.get('branchCode').value || '-1';

        const data = {
            branchCode: branchCode,
            giftUserId: this.giftUser.id
        };

        this.msConsumersService.updateConsumer(consumerId, data).subscribe();
    }

    public displayExtendedForm(): void {
        if (this.giftService.getConfigurationValue('simpleReward') === false
            && !this.showExtendedForm && this.form.controls.mobile.valid && this.form.controls.email.valid) {
            this.showExtendedForm = true;
            return;
        }
        this.dispatchSubmit();
    }

    dispatchSubmit() {
        this.consumersSearch = [];
        if (this.form.invalid) return;
        if (
            this.cookiesService.getCookie('last_branchcode_check') === null ||
            this.dateService.getDatesDiff(this.cookiesService.getCookie('last_branchcode_check') as unknown as Date, new Date(), 'days') > 14
        ) {
            const dialogRef = this.dialog.open(
                DialogUpdateBranchCodeComponent,
                {
                    maxWidth: 650,
                    panelClass: 'dialog-panel',
                    data: {
                        branchCodes: this.branchList
                    }
                }
            );
            dialogRef.afterClosed().subscribe(isUpdated => {
                if (this.branchList.length === 1 || !isUpdated) this.createConsumer();
            });
        } else this.createConsumer();
    }

    capitalizeInput(event: any) {
        this.form.get(event.target.id).setValue(this.capitalizePipe.transform(event.target.value));
    }

    getErrorMessage(f): string {
        if (this.form.controls[f].hasError('required')) return 'Champ requis';
        if (this.form.controls[f].hasError('email')) return 'Email non valide';
        if (this.form.controls[f].hasError('minlength')) return 'Minimum ' + this.form.get(f).errors['minlength']['requiredLength'] + ' caractères';
        if (this.form.controls[f].hasError('maxlength')) return 'Maximum ' + this.form.get(f).errors['maxlength']['requiredLength'] + ' caractères';
        if (this.form.controls[f].invalid) return 'Champ invalide';
    }

    selectConsumer(consumer: Consumer) {
        this.updateForm(consumer);
        this.consumersSearch = [];
    }

    formIsValid() {
        return this.form.valid || (this.giftService.getConfigurationValue('simpleReward') === false && !this.showExtendedForm &&
            this.form.controls.mobile.valid && this.form.controls.email.valid);
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }
}
